/* RESPONSIVE */
/* `sm` applies to x-small devices (portrait phones, less than 576px) */
/* @media (min-width: 328px) {
    .IframeCodesWidth {
    width: 200px !important;
    }
} */
.navbar-toggler:focus{
    box-shadow: none !important;
}
@media (max-width: 900px) {
  
.footerContainer{
  padding: 340px 0px 0px;
}

.carousel-item img{
  width: 100%;
  object-fit: contain;
}
  .navbar{
    display: flex;
    flex-direction: column;
    align-content: end;
   }
   body .ticket {
    margin: 0 0 0 0px;
    width: 155px;
    height: 50px;
    font-size: 14px;
    padding: 0 40px 0 0;
  }

  body .Header-Text {
    font-size: 16px;
  }
  .Header-logo{
    width: 250px !important;
    height: 100px !important;
  }
  .aboutBackContent{
    font-size: 16px !important;
  }
  .footer-bottom p {
    font-weight: 500;
    font-size: 12px;
    color: #fff;
  }
}



@media (max-width: 575.98px) {
  .Carousel-items-set {
    background-size: contain;
    height:inherit;
  }
    .Header-logo {
        width: 160px !important;
    height: 80px !important;
        position: relative;
    }
    .email-signup-main:before {
        content: "";
        background:none;
        width: 0px;
        height: 0px;
        display: block;
        position: absolute;
        left: 0;
        bottom: 0;
      }
.cardFlexCss{
  display: flex;
  flex-direction: column;
}
    /* .Joinus-section .Joinus-section-header {
        text-align: center;
        letter-spacing: 0.5px;
        line-height: 27px;
          } */
    .Joinus-section p{
        margin-bottom: 0px !important;
        font-weight: 700;
      }
    .Highlight-Section {
        padding: 11px 6px;
    }

    /* .HightlightWidget .Highlight-Header span::after {
        margin: 0 0 0 5px;
    }

    .HightlightWidget .Highlight-Header span::before {
        margin: 0 5px 0 0;
    } */

    .AdSectionContainer .card-widget-title {
        font: 26px "Oswald", Arial, Verdana, sans-serif;
        font-weight: 500;
    }

    .PromotionSection {
        padding: 20px;
    }

    .IframeCodesWidth {
        min-width: 180px;
        width: 360px;
    }

    .footerContainer {
        padding: 320px 0 0;
    }

    ul.footer-image {
        max-width: 100%;
    }

    /* EMAIL */
    .email-signup-main {
        margin: 10px 0 3px;
        padding: 34px 10px 25px 10px;
        overflow: hidden;
        min-height: 200px;
        max-height: auto;
        align-items: flex-start;
    }
    .email-signup-main:before {
        width: 0px !important;
        height: 0px !important;
        left: 0;
        bottom: -145px !important;
    }
    .PromotionSection {
        padding: 10px;
    }
    .footer-bottom p {
        font-weight: 500;
        font-size: 10px;
        color: #fff;
      }
}
@media (max-width: 380px) {
    .Header-logo {
        width: 100px !important;
        height: 50px !important;
       
    }
    .Carousel-items-set {
      background-size: contain;
      height:inherit;
    }
    .IframeCodesWidth {
    width: 310px !important;
    }
    .email-signup-main:before {
        content: "";
        background:none;
        width: 0px;
        height: 100px;
        display: block;
        position: absolute;
        left: 0;
        bottom: 0;
      }
      .footer-bottom p {
        font-weight: 500;
        font-size: 10px;
        color: #fff;
      }
    
}
/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
    .IframeCodesWidth {
        width: 400px;
    }
}

/* Medium devices (tablets, 768px and up) */

@media (min-width: 768px) {
    /* .IframeCodesWidth {
        min-width: 180px;
        width: 720px;
    } */
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
    .IframeCodesWidth {
      
    }
}

/* X-Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {}

/* XX-Large devices (larger desktops, 1400px and up) */
@media (min-width: 1400px) {}


@media (max-width:950px){
    .Joinus-section {
        padding: 0px 0px 0px 0px;
      }
      .Joinus-section p{
        margin-bottom: 0px !important;
      }
      .Joinus-section .Joinus-section-header {
        display: inline-block;
        font-size:12px;
        font-weight: normal;        
      }
      .yTubeContainer iframe{
        width: 100%;
        height: 300px;
      }
      .cardEventSection{
        display: block;
      }
      .cardFlexCss{
        display: flex;
        flex-direction: row;
      }
}



@media (max-width:500px){
    .Joinus-section {
        padding: 0px 0px 0px 0px;
      }
      .Joinus-section p{
        margin-bottom: 0px !important;
      }
      .Joinus-section .Joinus-section-header {
        display: inline-block;
        font-size:12px;
        font-weight: normal;   
      }
      .footer-info ul li.address:before{
        width: 30px;
      }
      
      .cardEventSection{
        display: block;
      }
      .cardFlexCss{
        display: flex;
        flex-direction: row;
        gap: 10px;
        padding: 10px;
      }
      /* .cardFlexCss img{
        width: 100%;
        height: 50%;
        object-fit: cover;
      } */
      .yTubeContainer iframe{
        width: 100%;
        height: 180px;
      }
      .MapSection {
        padding: 20px 0px;
      }
      .imgSlider img {
        width:90px !important;
        height: 50px !important;
      }
      .EventLive h5{
        font-size: 15px;
      }
      .EventLive span{
        font-size: 12px;
      }
}