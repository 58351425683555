.bannerBackground {
  background-image: url(../assets/EventDetail/card.png);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  min-height: 90vh;
  max-height: auto;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: auto;
}

.BackGroundBlur {
  background-color: none !important;
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(20px);
  padding: 20px 20px;
  height: 100%;
}

.bannerMain {
  box-sizing: border-box;
  margin-top: 50px;
  background: #FFFFFF;
  border-radius: 25px;
  padding: 0px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  /* min-height: 100vh; */
  max-height: auto;
}

.contactMain {
  box-sizing: border-box;
  margin-top: 100px;
  background: #FFFFFF;
  border-radius: 25px;
  padding: 0px !important;
}

.bannerImg {
  display: flex;
  justify-content: center;
  position: relative;
  overflow: hidden;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  padding-left: 0px !important;
  padding-right: 0px !important;
  position: relative;
}

.ticketDetailbanner {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.bannerImg img {
  width: 100%;
  object-fit: cover;
  object-position: 50% 20%;
}

.black {
  text-align: center;
  background: #000;
  background-blend-mode: multiply;
  color: #fff;
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: .5;
}

.flexCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}


.formBackground {
  background-color: #00005B;
  border-radius: 25px;

}

.Orange {
  color: orangered;
  text-decoration: underline;

}

.text-center {
  text-align: center;
}

.formBackground .formInput input {
  width: 100%;
  border: 1px solid #CECECE;
  padding: 4px 20px;
  margin: 5px 5px;
  border-radius: 4px;
  outline: none;
  color: black;
}

.formBackground .formInput textarea {
  width: 99.9%;
  border: 1px solid #CECECE;
  padding: 4px 20px;
  margin-left: 5px;
  border-radius: 4px;
  outline: none;
  color: black;
}

.formBackground .formInput {
  width: 100%;
  padding: 4px 10px;
  margin: 5px 5px;
  border-radius: 4px;
  outline: none;
  color: black;
}

.css-1jqq78o-placeholder {
  color: #000 !important;
}

.formBackground .sponsorSelect {
  width: 99.1%;
  padding-right: 0px;
  border-radius: 4px;
  outline: none;
  color: black;
  /* z-index: -99; */
}

.formBackground .formInput input {
  height: 50px;
}

.formBackground .formInput textarea {
  height: 120px;
}

.formBackground .formInput input::placeholder {
  color: black;
}

.formBackground .formInput textarea::placeholder {
  color: black;
}

.submitBtn {
  border: none;
  background-color: green;
  color: white;
  padding: 15px 20px;
  height: 50px;
  width: 120px;
}

.aboutContent {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 30px 0px;
  padding: 20px 0px;
}

.aboutContent a {
  color: #fa0000;
}

.aboutContent img {
  max-width: 100%;
  max-height: 100%;
  border-radius: 10px;
}

.aboutContent h3 {
  text-align: center;
}

.aboutContent span {
  font-size: 18px;
}

.vendorDescription span {
  font-size: 20px !important;
}

.festivalhourTitle u {
  font-size: 30px !important;
}

.footer ul {
  list-style-type: none;
  clear: both;
  padding: 0;
  margin-left: auto;
  margin-right: auto;
}

.footer-infoItem ul {
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-top: 10px;
}

.footer-infoItem ul li {
  display: flex;
  margin: 0 0 9px;
  font-size: 15px;
  padding: 0;
  letter-spacing: 0.3px;
  line-height: 26px;
  font-weight: 500;
  font-size: 17px;
}

.footer-infoItem ul li a {
  display: flex;
  font-weight: 500;
  font-size: 17px;
  color: inherit;
  text-decoration: none;

}

.footer-infoItem ul li.tel a:before {
  content: "";
  background: url(../assets/tel.png) no-repeat;
  width: 30px;
  height: 28px;
  display: inline-block;
  font-size: 18px;
  line-height: 1;
  color: #000;
  margin: 0 8px 0 0;
}

.footer-infoItem ul li.address:before {
  content: "";
  background: url(../assets/nav.png) no-repeat;
  width: 35px;
  height: 28px;
  display: inline-block;
  font-size: 18px;
  line-height: 1;
  color: #000;
  display: flex;
  font-size: 15px;
  margin: 0 8px 0 0;
}

.footer-infoItem ul li.mail a:before {
  content: "";
  background: url(../assets/email.png) no-repeat;
  width: 30px;
  height: 28px;
  display: inline-block;
  font-size: 18px;
  line-height: 1;
  color: #000;
  margin: 0 8px 0 0;
}

@media (max-width:950px) {
  .sponserHeading h4 {
    font-size: 25px !important;
  }

  .Orange {
    font-size: 25px !important;
  }

  .formBackground .formInput input {
    font-size: 13px !important;

  }

  .css-1jqq78o-placeholder {
    font-size: 13px !important;
  }

  .formBackground .formInput textarea {
    font-size: 13px !important;
  }

  .submitBtn {
    font-size: 14px !important;
  }

  .aboutContent div {
    margin: 10px 10px;
    padding: 0px 10px;
  }

  .formBackground .sponsorSelect {
    width: 98% !important;
  }

  .vendorDescription span {
    font-size: 16px !important;
  }

  .festivalhourDescription span {
    font-size: 16px !important;
  }

  .festivalhourTitle u {
    font-size: 25px !important;
  }
}

@media (max-width:500px) {
  .aboutContent {
    padding: 20px 10px;
  }

  .aboutContent img {
    max-width: 100%;
    max-height: 100%;
  }

  .eventDetailPara {
    margin-top: 4px;
  }

  .eventDetailPara h6 {
    font-size: 12px;
  }

  .eventDetailPara span {
    font-size: 12px;
  }

  .sponserHeading h4 {
    font-size: 20px !important;
  }

  .Orange {
    font-size: 20px !important;
  }

  .formBackground .formInput input {
    font-size: 13px !important;

  }

  .css-1jqq78o-placeholder {
    font-size: 13px !important;
  }

  .formBackground .formInput textarea {
    font-size: 13px !important;
  }

  .submitBtn {
    font-size: 14px !important;
  }

  .formBackground .sponsorSelect {
    width: 96% !important;
  }

  .vendorDescription span {
    font-size: 14px !important;
  }

  .festivalhourTitle u {
    font-size: 20px !important;
  }

  .festivalhourDescription span {
    font-size: 14px !important;
  }
}

@media (max-width:500px) {
  .eventDetailPara h6 {
    font-size: 10px;
  }

  .eventDetailPara span {
    font-size: 12px;
  }
}