.eventMain {
  background: url(../assets/EventDetail/card.png);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  min-height: 100vh;
  max-height: auto;
  display: flex;
  width: 100%;
  margin: auto;
}

.eventBlur {
  background-color: none !important;
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(20px);
  padding: 20px 20px;
}

.eventContainer {
  box-sizing: border-box;
  margin-top: 100px;
  background: #ffffff;
  border-radius: 10px;
  padding: 10px 0px;
}

.eventListCardScroll {
  gap: 20px;
  display: flex;
  flex-direction: column;
}

.eventListCard {
  padding: 20px 30px;
  border-radius: 27.023px;
  background: #FFF;
  box-shadow: 2.70225191116333px 2.70225191116333px 13.511259078979492px 0px rgba(0, 0, 0, 0.10);
}

.eventListCard h6 {
  font-weight: 600;
}

.eventContent h5 {
  font-size: 30px;
  font-weight: 600;
}

.eventDetails img {
  max-width: 20px;
  height: 20px;
}

.eventDetails h6 {
  font-size: 15px;
  margin-top: 3px;
}

.eventDate {
  padding-right: 10px;
  padding-left: 10px;
}

.EventImageCard {
  max-width: 100%;
  /* max-height:200px; */
}

.EventImageCard img {
  max-width: 100%;
  max-height: 100%;
  border-radius: 25px;
  object-fit: contain;
  box-shadow: 2.70225191116333px 2.70225191116333px 13.511259078979492px 0px rgba(0, 0, 0, 0.10);
}

.viewbtn {
  border: none;
  outline: none;
  width: 100%;
  background-color: #000000e3;
  padding: 8px;
  border-radius: 7px;
  color: white;
  font-size: 16px;
}

.Noevent {
  max-width: 50%;

}

@media (max-width:950px) {
  .eventListCard {
    width: 90%;
  }

  .eventDetails {
    gap: 7px;
  }

  .eventHeading h1 {
    font-size: 28px !important;
  }

  .eventContent h5 {
    font-size: 25px;

  }

  .eventContent h6 {
    font-size: 16px;
    font-weight: 600;
  }

  .eventDetails span {
    font-size: 14px;
  }

  .eventHeading {
    width: 50% !important;
  }

  .eventTranslate {
    width: 50% !important;
  }

  .EventImageCard {
    display: flex;
    align-items: center;
  }
}

@media (max-width:600px) {
  .eventMain {
    min-height: auto;
  }

  .eventContainer {
    margin-top: 40px;
  }

  .eventContent h5 {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 20px;
  }

  .eventContent h6 {
    font-size: 14px;
    font-weight: 600;
  }

  .eventDetails span {
    font-size: 12px;
  }

  .eventHeading h1 {
    font-size: 23px !important;
  }

  .viewbtn {
    font-size: 14px;
  }

}