.TicketMain{
    background: url(../assets/EventDetail/card.png);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    min-height: 100vh;
    max-height: auto !important;
}
.TicketBlur{
    background-color: none !important;
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(20px);
  padding: 50px 30px
  }
.ticketContainer{
    box-sizing: border-box;
    margin-top: 100px;
    background: #ffffff;
    border-radius: 25px;
    padding: 0px !important;
    /* min-height: 150vh;
    max-height: ; */
   min-height:  100vh;
   max-height: fit-content;
   padding: 50px 0px
}
.ticketDetailbanner{
    margin-left: 0px !important;
    margin-right: 0px !important;
 }
 .bannerBgImageMain {
    display: flex;
    justify-content: center;
    position: relative;
    overflow: hidden;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  
  .bannerBgImageMain img {
    width: 100%;
    object-fit: cover;
    object-position: 50% 20%;
  }
  
  .black {
    text-align: center;
    background: #000;
    background-blend-mode: multiply;
    color: #fff;
    width: 100%;
    height: 100%;
    position: absolute;
    opacity: .7;
  }
  
.bannerBgoverlay {
    color: #fff;
    font-size: 1em;
    align-self: center;
    position: absolute;
    z-index: 10;
    width: 100%;
    text-align: center;
}
  .TicketSelect{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 0px !important;
     gap: 10px;
}

.ticketSection h2{
    font-size: 22px;
    font-weight: 600;
}

.TicketBuyBtn{
    width: 150px;
    /* text-decoration: none; */
    padding: 5px 10px;
    color: #fff;
    font-size: 20px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    border: none;
    outline:none;
    background-color: red;
    transition: 0.2s ease-in-out;
 }

 .TicketBuyBtn:hover{
    border: none;
    outline: none;
    background: red !important;
    box-shadow: 5px 5px 0px #000;
 }
.ticketTitle h4{
    font-size: calc(21px + 5 * ((100vw - 450px) / 916));
    font-weight: 900;
    /* color: #00425F; */
    margin: 0 0 0.67em 0;
}

.ticketTitle h6{
    font-size: calc(18px + 5 * ((100vw - 450px) / 916));
    font-weight: 900;
    /* color: #00425F; */
    margin: 0 0 0.67em 0;
}
.ticketListMain{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 20px;
}
.ticketmap{
    padding: 20px 30px;
  }

.ticketList{

    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;    border-radius: 10px;

}
.ticketHeading h1{
    color: #000;
    font-size: 25px;
    font-weight: 600;
}
.ticketHeading{
    font-weight: 800;
    font-size: 18px;
    color: #4169E1;
}
.ticketnameli h5{
    font-size: 20px;
    font-weight: 600;   
    color: #4169E1;
}
.ticketLocateContent{
    display: block;
    justify-content:center;
}
.ticketLocateContent h6{
    font-weight: 600;
    margin-bottom: 0px;
}
.ticketLocateContentSpan{
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 6px;

}
.ticketSectionHead{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 15px 0px;
    text-transform: uppercase;
}
.ticketTypeRedem{
    display: block;
}
.ticketTypeRedem h6{
   margin-bottom: 0px;
line-height: normal !important;
}
/* .ticketSectionType h5{
    font-weight: 600;
    font-size: 20px;
    color: #4169E1;
} */
.ticketSectionType h6{
    font-weight: 600;
    font-size: 20px;
    margin-bottom: 15px;
}
.ticketDetailBtn{
    border: none !important;
    outline: none;
    box-sizing: border-box;
    display: inline-flex;
    align-items: center;
    background: #ff8f15 !important;
    color: #fff;
    padding: 0.875em 1.75em 0.75em 1.25em;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    font-weight: 600;
    font-size: 18px;
    text-transform: uppercase;
    line-height: 1;
    min-height: 44px;
    max-height: auto;
    -webkit-clip-path: polygon(100% 0, 96% 50%, 100% 100%, 0 100%, 0 0);
    clip-path: polygon(100% 0, 96% 50%, 100% 100%, 0 100%, 0 0);
    text-decoration: none;
}
.ticketDetailBtnBuy{
    border: none !important;
    outline: none;
    box-sizing: border-box;
    display: inline-flex;
    align-items: center;
    background: #ff8f15 !important;
    color: #fff;
    padding: 0.875em 1.75em 0.75em 1.25em;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    font-weight: 600;
    font-size: 18px;
    text-transform: uppercase;
    line-height: 1;
    min-height: 44px;
    max-height: auto;
    -webkit-clip-path: polygon(100% 0, 96% 50%, 100% 100%, 0 100%, 0 0);
    clip-path: polygon(100% 0, 96% 50%, 100% 100%, 0 100%, 0 0);
    text-decoration: none;
    width: 100%;
    display: flex;
    justify-content: center;
}
.ticketBtnList{
    display: flex;
    justify-content: end;
    align-items: center;
    flex-direction: column;
}
/* .modal-header{
    background: #ff8f15 !important;
    color: #fff;
    padding: 8px !important;
} */
.checkMain h5{
    font-size: calc(20px + 13 * ((100vw - 450px) / 916));
    font-weight: 900;
    letter-spacing: 1px;
}
.checkhr{
    width: 100%;
    height: 5px !important;
    background-color: gray !important;
    border-top: none !important;
}
.cartCard{
    width: 100%;
    margin: 0 auto 1em;
    box-shadow: 0 2px 6px rgba(0,0,0,.2);
    border-radius: 10px;
}
.cartCard img{
  width: 140px;
  height: 110px;
}
.ticketBgList{
    /* background: url(../assets/EventDetail/TicketList.svg);
    background-repeat: no-repeat;
    background-position: top;
    background-size: contain;
    display: flex;
    justify-content: flex-start;
    align-items:center;
    width: 100%;
    height: 300px; */
}
.ticketBgList p{
    margin-bottom: 0px !important;
}
.NoTicket{
    max-width: 80%;

}
.ticketBgtr{
    display: flex;
    justify-content: space-between;
   margin-bottom: 4px;
}
.ticketBgtr td{
    display: flex;
    justify-content: flex-start;
    width: 140px !important;
    
}
.ticketTotal{
    width: 250px !important;
    display: flex;
    justify-content: space-between;
   margin-bottom: 4px;
}
.totalpricetr td{
    background: #ff8f15 !important;
    color: #fff;
    padding: 10px !important;
}
.totalreview{
    border: 1px solid #00425F;
    /* background: #ff8f15 !important; */
    /* color: #00425F; */
    padding: 8px !important;
    text-align: center;
    display: flex;
    gap: 10px !important;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 10px;
}

.payment {
    display: grid;
    justify-content: center;
    align-content: center;
    height: 50vh;
}
/* .cardEmail{
    margin: 10px 0px;
} */
.cardEmail input{
    border: none;
    outline: none;
    border:1px solid #d9d9d9 ; 
    width: 100%;
    padding: 10px 15px;
    border-radius: 5px;
}
.cardEmail input::placeholder{
    color: #000;
}
.cardEmail input:focus{
    border:2px solid #1155cc;
}

.paySubmit{
    position: relative;
    top: -45px;
    left: 0px;
    width: 100%;
    height: 40px;
    opacity: 0;
    z-index: 1;
    /* border: none; */
    /* outline: none; */
    /* background-color: transparent; */
}
.ticketLocate{
    display:flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex-direction: column;
    text-align: center;
    padding: 10px 20px 10px 0px;
}
.ticketLocate h3{
    font-weight: 600;
}
.ticketLocate button{
    border:none;
    outline: none;
    background-color: red;
    color: #FFFFFF;
    padding: 10px 20px;
    font-weight: 600;
}

.captcha {
    display: flex;
    justify-content: center;
    align-items: center;

}
.captcha .input{
    width: 295px;
    height: 30px;
    border: rgb(122, 195, 238) 2px solid;
    display: block;
    margin-bottom: 10px;
    border-radius: 3px;
    outline: none;
  }
  
 
  
  .captcha  label{
    display: block;
    margin-bottom: 2px;
  }
  
  .captcha button{
    padding: 7px;
    margin-top: 5px;
    /* width: 300px; */
    background-color: #ff8f15;
    border: none;
    border-radius: 4px;
    color: #FFFFFF;
    font-weight: 600;
  }
.ticketSummary{
    height: 100%;
    display: flex;
    justify-content: end;
    align-items: flex-start;
    height: 90vh;
}

.ticketHeadQuantity{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}
.ticketQuantityAvail{
    background-color: #ff8f15;
    padding: 10px 15px;
    border-radius:10px;
    margin-left: 20px;
   font-size: 15px;
   font-weight: 600;
   color: #FFFFFF;
}
.ticketPaymentSection{
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 120vh;
    flex-direction: row;
}

.ticketListRow>div{
    border-bottom: 0.5px solid #b7b7b7;
    padding: 5px 0px;
}
.ticketHeadmargin{
    margin-bottom: 0px;
}
.ticketPaymentCol{
    display: flex;
    justify-content: flex-start;
}
.ticketEvent h6{
    font-weight: 600;
    margin-bottom: 0px;
}
.ticketEvent h5{
    font-weight: 600;
}
.ticketLocateContent h5{
    font-weight: 600;
}
.ticketLocateContent>div{
    margin-bottom: 10px;
}
.ticketDesCol{
    margin-top: 15px !important;
}
.scrollArrow{
    display: none;
}
.inputPad{
    margin:5px 0px;
    padding: 0px 0px;
}
.inputPad small{
    color: #000;
    font-weight: 400;
}
.inputPad p{
    color: red;
    margin-bottom: 0px;
}
.inputPad input:focus{
box-shadow: none !important;
}
.TicketDateClassMain{
    display: flex;
    justify-content: center;
    width: 100%;
}
.TicketDateClass{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 15px;
    padding: 0px 15px;
    width: 100%;
    font-weight: 600;
}
.ticketchoosse{
    font-weight: 600 !important;
    font-size: 20px !important;
    color: #4169E1 !important;
  }
.ticketSelect{
    font-size: 20px;
    padding: 0px 5px;
    background-color: #FFFFFF;
    border: none;
    outline: none;
    margin: 5px 0px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
     display: flex;
     align-items: flex-start;
     border-radius: 5px;
 }
 .ticketdecriptionpara {
    font-size: 20px;
    /* font-weight: 600; */
    color: #4169E1;
}
.ticketdescriptiontitle{
    font-size: 20px !important;
    font-weight: 600 !important;
    color: #000 !important;
        }
        .ticketuseDate{
            font-size: 20px !important;
    font-weight: 600 !important;
    color: #000 !important;
        }
       
@media (max-width:950px) {
    .ticketSectionType h5 {
        font-size: 18px;
    }
    .ticketSectionType h6 {
        font-size: 15px;
        text-align: left !important;
        padding-top: 0px;
    }
   .ticketDesCol{
    margin-top: 15px;
   }
    .ticketLocateContentSpan{
        font-size: 15px;
        margin-bottom: 6px;
    }
    .ticketSectionType p{
        font-size: 14px;
    }
    .ticketHeading h1{
        font-size: 25px;
    }
    
    .ticketSection h2 {
        font-size: 20px;
    }
    .ticketEvent h5{
        font-size: 18px ;
    }
    .ticketLocateContent h5{
        font-size: 18px;
    }
    .ticketLocateContent{
        justify-content: left !important;
        margin-top: 10px;
    }
    .ticketLocateContent h6{
       text-align: left !important;
       font-size: 15px;
    }
    .ticketTypeRedem{
        display: flex;
        justify-content: flex-start;
        margin-top: 15px;
        flex-direction: column;
        align-items: flex-start !important;
    }
    .ticketDesheading h6{
        font-size: 18px !important;
    }
    .ticketDesheading p{
        font-size: 16px !important;
    }


    .ticketListContent h5{
        font-size: 18px;
     }
     .ticketListContent p{
         font-size: 18px;
      }
      .ticketBgList {
         padding-top: 10px;
         /* padding-left: 20px; */
      }
      .ticketBgList p{
         font-size: 18px;
      }
      .ticketBgList h5{
         font-size: 18px;
      }
      .ticketSummaryHeading{
         font-size: 24px !important;
      }
      .ticketSummary h5{
         font-size: 18px !important;
      }
      .cardEmail label {
         font-size: 18px;
      }
      .cardEmail input {
         font-size: 16px;
      }
      .ticketTotal td{
           font-size: 16px;
      }
      .ticketDetailbanner img{
        height: 300px;
      }
      .checkTitle{
        width: 50% !important;
      }
      .checkBtn{
        width: 50% !important;
      }
      .ticketSignup{
        font-size: 18px !important;
      }
      .ticketPaymentSection{
        display: flex;
        justify-content: center;
        align-items: start;
        margin: 15px 0px;
        flex-direction:column-reverse;
        /* gap: 40px; */
    }
    .ticketSummary{
        display: flex;
        justify-content: center;
        margin: 20px 0px;
        align-items: flex-start;
        height: 40vh;
    }
    .ticketPaymentCol{
        display: flex !important;
        justify-content: center !important;
        min-height: 150vh;
        max-height: max-content;
        align-items: center;
        width: 100% !important;
    }
    .ticketListContent{
        padding-left: 20px;
    }
    .ticketDetailBtn {
        font-size: 14px !important;
    }
}

  @media (max-width:600px) {
    .scrollArrow{
        display: block;
    }
    .chevroncontainer{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100px;
    }
    .TicketDateClass{
       font-size: 13px;
    }
    .ticketdescriptiontitle {
        font-size: 14px !important;
        font-weight: 600 !important;
        color: #000 !important;
    }
    .chevron {
        position: absolute;
        width: 3.5rem;
        height: 0.8rem;
        opacity: 0;
        transform: scale(0.3);
        animation: move-chevron 3s ease-out infinite;
      }
      
      .chevron:first-child {
        animation: move-chevron 3s ease-out 1s infinite;
      }
      
      .chevron:nth-child(2) {
        animation: move-chevron 3s ease-out 2s infinite;
      }
      
      .chevron:before,
      .chevron:after {
       content: '';
       position: absolute;
       top: 0;
       height: 100%;
       width: 50%;
       background: #2c3e50;
      }
      
      .chevron:before {
       left: 0;
       transform: skewY(30deg);
      }
      
      .chevron:after {
       right: 0;
       width: 50%;
       transform: skewY(-30deg);
      }
      .ticketchoosse{
        font-weight: 600 !important;
        font-size: 20px !important;
      }
      .ticketnameli h5{
        font-weight: 600 !important;
        font-size: 20px !important;
        margin-bottom: 20px;
      }
      @keyframes move-chevron {
       25% {
        opacity: 1;
          }
       33.3% {
        opacity: 1;
        transform: translateY(3.8rem);
       }
       66.6% {
        opacity: 1;
        transform: translateY(5.2rem);
       }
       100% {
        opacity: 0;
        transform: translateY(8rem) scale(0.5);
       }
      }
    .checkMain{
        display: flex;
    flex-direction: column;
    align-items: center;
    }
    .TicketMain{
        min-height: auto !important
    }
    .ticketDateSpan{
    font-size: 13px;
    }
    .ticketContainer{
        margin-top: 10px;
    }
   .bannerBgoverlay h4{
        font-size: 20px;
    }
    .ticketModaltr th{
        min-width: 180px !important;
    }
    .ticketLocateContentSpan{
        font-size: 14px;
    }
    .TicketSelect{
        display: flex;
        flex-direction: column;
        gap: 10px;
        padding: 0px 0px 0px 20px;
    }
    .TicketSelect select{
        padding: 0px 0px 0px 10px;
        width: 180px;
        justify-content: flex-end;
        height: 40px;
        font-size: 13px !important;
    }
    .ticketLocate h3{
        font-size: 18px;
    }
    .ticketLocate button{
        font-size: 10px;
    }
    /* .ticketHeading h1{
        font-size: 18px;
    } */
    .ticketLocateEvent img{
        width: 18px;
    }
    .ticketLocateEvent h6{
       font-size: 14px;
       margin: 0px !important;
    }
    .ticketLocateEvent h5{
        font-size: 14px;
     }
     .ticketSection h2{
        font-size: 24px;
        color: #4169E1;
     }
     
     .ticketBtnList{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    .ticketLocateContent{
        margin-top: 20px;
        display:block;
    }
   
    .ticketHeadmargin{
        margin-bottom: 8px;
    }
    .ticketListRow>div{
        border-bottom: 0;
        padding: 5px 0px;
    }

    .ticketListVal{
        display: block !important;
    }
    .ticketEvent{
        display: flex;
        flex-direction: column;
    }
    .ticketEvent h6{
        font-size: 13px;
    }
    .ticketSectionHead img{
        width: 35px;
    }
  .ticketSectionlast{
    display: flex;
    flex-direction: column;
  }
    .ticketSectionType h5 {
        font-size: 15px;
        font-weight: 600;
        color: #4169E1;
    }
    .ticketSectionType h6 {
        font-size: 14px;
        text-align: left !important;
        padding-top: 0px;
    }
    .ticketSectionType{
        /* display: flex;
        justify-content: start;
        flex-wrap: wrap; */
    }
    .ticketSectionType{
        margin-top: 20px;
    }
    .ticketSectionType img{
        width: 18px;
    }
   
    .ticketSectionType p{
        font-size: 14px;
    }
    .ticketDetailBtn{
    font-weight: 500;
    text-transform: uppercase;
    min-height: 24px;
    max-height: auto;
    font-size: 12px;
     padding: 5px 8px 4px 6px;
    }
    .ticketSummary{
        display: flex;
        justify-content: center;
        margin: 20px 0px;
    }
    .ticketPaymentSection{
        display: flex;
        justify-content: center;
        align-items: start;
        margin: 15px 0px;
        flex-direction:column-reverse;
    }
    .choosePriceCenter{
        width: 100px !important;
        text-align: center;
    }
    .ticketDesheading h6{
        font-size: 14px !important;
    }
    .ticketDesheading p{
        font-size: 13px !important;
    }


    .ticketListContent h5{
       font-size: 14px;
    }
    .ticketListContent p{
        font-size: 14px;
     }
     .ticketBgList {
        padding-top: 10px;
        padding-left: 0px !important;
     }
     .ticketBgList p{
        font-size: 14px;
     }
     .ticketBgList h5{
        font-size: 14px;
     }
     .ticketSummaryHeading{
        font-size: 16px !important;
     }
     .ticketSummary h5{
        font-size: 14px !important;
     }
     .cardEmail label {
        font-size: 14px;
     }
     .cardEmail input {
        font-size: 13px;
     }
     .ticketTotal td{
          font-size: 14px;
     }
     .ticketDetailbanner img{
        height: 200px;
      }
      .ticketSignup{
        font-size: 14px !important;
      }
      .c-jWYnUm-ijagetZ-css {
        font-size: 14px !important;

      }
      .ticketListContent{
        padding-left: 0px !important;
    }
     .scrollArrow button{
        border: none;
        outline: none;
        background-color: red;
        color: #FFFFFF;
        padding: 10px 20px;
        border-radius: 8px;
        margin-top: 20px;
     }
     .ticketHeadingAnn{
        font-size: 13px !important;
        font-weight: 600 !important;
    }
    .paymentInstruction li{
            font-size: 13px;
    }
  }
  .ticketSummaryHeading{
    font-size: 1.5rem;
 }


 
 @media (max-width:425px) and (min-width:400px){
    .ticketSummary{
        display: flex;
        justify-content: center;
        margin: 20px 0px;
        align-items:center;
        height: 85vh;
    }
    .ticketPaymentCol{
        display: flex !important;
        justify-content: center !important;
        min-height: 150vh;
        max-height: max-content;
        align-items: center;
        width: 100% !important;
    }
 }
 @media (max-width:400px) and (min-width:375px){
    .ticketSummary{
        display: flex;
        justify-content: center;
        margin: 20px 0px;
        align-items:center;
        height: 60vh;
    }
    .ticketPaymentCol{
        display: flex !important;
        justify-content: center !important;
        min-height: 100vh;
        max-height: max-content;
        align-items: center;
        width: 100% !important;
    }
 }
@media (max-width:375px) {
    .ticketSummary{
        display: flex;
        justify-content: center;
        margin: 20px 0px;
        align-items:center;
        height: 70vh;
    }
    .ticketPaymentCol{
        display: flex !important;
        justify-content: center !important;
        min-height: 150vh;
        max-height: max-content;
        align-items: center;
        width: 100% !important;
    }
    
    .ticketDateSpan{
font-size: 11px;
    }
    .ticketEvent h5{
        font-size: 13px;
        font-weight: bold;
    }
    .ticketLocateContentSpan{
        font-size: 13px;
        margin-bottom: 5px;
    }
    .ticketEvent h6{
        font-size: 11px;
    }
    .ticketLocateContent{
        margin-top: 10px;
    }
    .ticketSectionType{
        margin-top: 10px;
    }
    .ticketTypeRedem{
        margin-top: 5px;
    }
    .ticketLocateContent h5{
        font-size: 13px;
        font-weight: bold;
    }
    .ticketLocateContent h6{
        font-size: 12px;
    }
    .ticketHeadQuantity h2{
        font-size: 19px;
    }
    .ticketQuantityAvail{
        font-size: 10px;
        padding: 5px 7px;
    }
    .ticketDetailBtn {
        font-size: 10px !important;
    }
    .ticketLocateContent img{
        width: 14px;
    }
    .ticketEvent img{
        width: 14px;
    }
    .ticketSectionType img{
        width: 14px;
    }
    .ticketSectionType h5{
        font-size: 15px;
        font-weight: bold;
    }
    .ticketSectionType h6{
        font-size: 13px;
    }
    .ticketdescriptiontitle{
font-size: 13px;
    }
    .ticketSectionType p{
        font-size: 14px;
        font-weight:bold;
    }
    .TicketDateClass{
        gap: 5px;
        font-size: 14px;
    }
    

}
@media (max-width:320px) {
    .payment{
        width: 90%;
        height: 60vh;
    }
    .ticketPaymentCol {
        display: flex !important;
        justify-content: center !important;
        min-height: 131vh;
        max-height: max-content;
        align-items: center;
        width: 100% !important;
    }
    .rccs__card {
        width: 230px;
    }
    .TicketBlur {
        padding: 45px 10px;
    }
}