.eventMainDetail {
  background: url(../assets/EventDetail/card.png);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  min-height: 100vh;
  max-height: auto;
  display: flex;
  width: 100%;
  margin: auto;
  /* padding: 20px 20px; */
}

.eventBlur {
  background-color: none !important;
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(20px);
  padding: 20px 20px;
}

/* background-color: none !important;
background: rgba(0, 0, 0, 0.871), url('../assets/LoginBgImg.jpg') !important;
box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
backdrop-filter: blur(10px);
-webkit-backdrop-filter: blur(20px);
border-radius: 10px;
border: 1px solid rgba(255, 255, 255, 0.18);
height: 0vh;
min-height: 100vh; */
.eventDetailContainer {
  backdrop-filter: blur(10px);
  box-sizing: border-box;
  margin-top: 100px;
  background: #ffffff;
  border-radius: 25px;
}

.eventDetailContainer .eventDetailbanner img {
  padding-left: 0px !important;
  padding-right: 0px !important;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
}

.eventDetailBtn {
  border: none;
  outline: none;
  box-sizing: border-box;
  display: inline-flex;
  align-items: center;
  background: #ff8f15;
  color: #fff;
  padding: 6px 20px 6px 4px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  font-weight: 800;
  text-transform: uppercase;
  line-height: 1;
  min-height: 44px;
  -webkit-clip-path: polygon(100% 0, 96% 50%, 100% 100%, 0 100%, 0 0);
  clip-path: polygon(100% 0, 96% 50%, 100% 100%, 0 100%, 0 0);
}

.eventDetailBtnsvg {
  position: relative;
  top: -2px;
  width: 1em;
  margin-left: 0.75em;
}
.eventDetailDateList{
  display: flex;
    justify-content: flex-start;
    gap: 20px;
    width: 100%;
    font-weight: 600;
}
.EventDetailContentMain {
  display: flex;
  justify-content: center;
  align-items: center;

}

.EventDetailContentMain .EventDetailImg {
  width: 50%;
}

.EventDetailContentClo {
  flex-direction: column;
}

.EventDetailContent {
  display: flex;
  justify-content: center;
  /* align-items:center ; */
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 30px;
  padding: 10px 30px;
}

.EventDetailContent h1 {
  font-size: 40px;
  font-weight: 700;
  letter-spacing: 0px;
}

.EventDetailContent p {
  font-size: 20px;
  margin-bottom: 5px !important;
}

.EventDetailContent h6 {
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 0px;
  padding: 10px 0px;
}
.eventdecriptionHead{
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 0px;
  padding: 10px 0px;
}
.EventTicketContentTick {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  /* align-items: center; */
}

.EventTicketSection {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.EventDetailHeading {
  display: flex;
  justify-content: flex-start;
  align-content: start;
  flex-direction: column;
  padding: 0px 30px !important;
}
.eventListDate{
  display: flex;
  gap: 10px;
  justify-content: flex-end;
  width: 280px;
  font-weight: 600;
}

.eventDetailPara span {
  font-size: 14px;
}
.eventDetailPara{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  width: 330px;
}
.eventDetailPara .eventDetailDateImg{
  width: 10%;
}
.eventDetailDate{
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  width: 100%;
}
.eventDetailPara h6 {
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0px;
  padding: 0px 0px;
  margin-bottom: 0px;
}

.EventTicketContent {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.EventTicketContentCol {
  display: flex;
  justify-content: center;
  text-align: center;
}

.EventBuyBtn {
  width: 150px;
  height: 40px;
  /* text-decoration: none; */
  padding: 5px 10px;
  color: #fff;
  text-decoration: none;
  font-size: 20px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  border: none;
  outline:none;
  background-color: red;
  transition: 0.2s ease-in-out;
}

.EventBuyBtn:hover{
  border: none;
  outline: none;
  background: red !important;
  box-shadow: 5px 5px 0px #000;
}

/* MAP SECTION */
.EventMapSection {
  /* min-height: 381px; */
  display: flex;
  justify-content: center;

}

.EventMapSection .map-content {
  width: 352px;
  display: flex;
  background: #fff;
  border-radius: 8px;
  flex-direction: column;
  align-items: center;
  padding: 9px 0 0;
  box-sizing: border-box;
  box-shadow: 2px 1px 4px 0px rgba(0, 0, 0, 0.2);
  position: relative;
}

.NoTicket {
  max-width: 50%;
}

.eventAannouncement{
  font-size: 20px;
  font-weight: 600;
  /* color: #214081; */
}

.eventDescription {
  font-size: 20px;
  color: #214081; 
} 
@media (max-width:950px) {
  .EventDetailContent h1 {
    font-size: 30px;
  }

  .EventDetailHeading h6 {
    font-size: 16px;
    font-weight: 600;
  }

  .EventDetailHeading span {
    font-size: 16px;
  }

  .eventDescription {
    font-size: 16px;
  }

  .EventDetailContent p {
    font-size: 16px;
  }

  .EventTicketSection h3 {
    font-size: 22px;
  }

  .eventDetailBtn {
    font-size: 14px;
  }

  .EventDetailHeading {
    padding: 0px 20px !important;
  }

  .eventDetailbanner img {
    height: 300px;
  }
}

@media (max-width:600px) {
  .EventDetailContent {

    padding: 10px 0px;
  }

  .eventMainDetail {
    min-height: auto;
  }

  .EventDetailHeading {
    padding: 0px 10px !important;
  }

  .eventDetailContainer {
    margin-top: 10px;
  }

  /* .EventDetailHeading h1{
        font-size: 24px;
    }
    .EventDetailHeading h6{
        font-size: 15px;
    } */
  .EventDetailHeading h1 {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 20px;
  }

  .EventDetailHeading h6 {
    font-size: 14px;
    font-weight: 600;
  }

  .EventDetailHeading span {
    font-size: 14px;
  }

  .eventDescription {
    font-size: 14px;
  }

  .EventDetailContent p {
    font-size: 14px;
  }

  .EventTicketSection h3 {
    font-size: 16px;
  }

  .eventDetailBtn {
    font-size: 12px;
    min-height: 35px;
  }

  .eventDetailbanner img {
    height: 180px;
  }
  .eventListDate{
    display: flex;
    gap: 10px;
    justify-content: flex-start;
    width: 270px;
  }
  .eventListCard {
    width: 96%;
}
.eventContent h5 {
  font-size: 16px !important;
}

.eventAannouncement{
  font-size: 14px;
}
}