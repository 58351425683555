.BackGroundBlurRedeem {
  background-color: none !important;
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(20px);
  padding: 20px 20px;
}

.bannerMainRedeem {
  box-sizing: border-box;
  margin-top: 100px;
  background: #FFFFFF;
  border-radius: 25px;
  padding: 0px !important;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  height: 100vh;
  overflow-y: scroll;
}

.RedeemContain {
  width: 100%;
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  margin: 10px 10px;
  border-radius: 10px;
}

.RedeemContain h6 {
  font-size: 18px;
}

.LogoFestivImage {
  max-width: 100%;
}

.LogoFestivImage img {
  max-width: 100%;
}

.ticketQRImage {
  max-width: 100%;
}

.ticketQRImage img {
  width: 90%;
}

.TicketRedeemId {
  width: 100% !important;
  border: 1px dashed #000;
  padding: 10px 10px;
  margin: 20px 10px;
  text-align: center;
}

.flex {
  padding: 10px 0px;
}

.ticketRedeemHead {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.tiketRedeembutton {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tiketRedeembuttonClick {
  border: none;
  outline: none;
  padding: 10px 40px;
  margin: 20px 0px;
  border-radius: 5px;
  background-color: red;
  color: #FFF;
  font-size: 20px;
  font-weight: 600;
  width: 300px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.DisabledBtn {
  background-color: gray;
  color: #FFF;
  font-size: 20px;
  font-weight: 600;
  max-width: 550px !important;
  border: none;
  outline: none;
  padding: 10px 40px;
  margin: 20px 0px;
  border-radius: 5px;
}

.tiketRedeembutton button {
  position: relative;
  animation-name: shakeAnim;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in;
}

.bt-shake:hover {
  animation-name: shakeAnimStop;
}

.Liveannouncement {
  margin: 20px 0px;
  justify-content: center;
  align-items: center;
  text-align: center;
  border: 3px dotted #000;
  background-color: red;
  color: white;
}

.disclamierRedeem{
  font-size: 14px;
}



@keyframes shakeAnimStop {
  0% {
    left: 0
  }
}

@keyframes shakeAnim {
  0% {
    left: 0
  }

  10% {
    left: -3px
  }

  20% {
    left: 3px
  }

  30% {
    left: -8px
  }

  40% {
    left: 8px
  }

  50% {
    left: -5px
  }

  60% {
    left: 3px
  }

  70% {
    left: 0
  }
}

@media (max-width:600px) {
  .RedeemContain {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .ticketRedeemHead {
    text-align: left;
  }

  .flex {
    padding: 10px 0px;
    text-align: left;
  }
  
  .NoteRedeem{
    font-size: 14px;
  }
  .disclamierRedeem{
    font-size: 10px;
  }
  .flex>div{
    padding: 0px 0px;
  }
  .ticketRedeemHead {
    padding-left: 0px !important;
  }
  .Liveannouncement h5{
    font-size: 14px;
  }
  .TicketRedeemId {
    text-align: center;
    margin: 0px 0px !important;

  }

  .TicketRedeemId h1 {
    font-size: 18px;
  }

  .RedeemContain h6 {
    font-size: 15px;
  }

}